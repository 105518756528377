import { MapLayer } from "./maps-base-layer";
import { getMapImageryUrl, safelyAddLayer, safelyAddSource, safelyRemoveLayer, safelyRemoveSource } from "./maps.utils";

export class MapRasterLayer extends MapLayer {

  constructor(private url: string, private coordinates: any, private projectId: number) {
    super();
  }

  async addTo(map: mapboxgl.Map): Promise<void> {
    this.map = map;
    const url = await getMapImageryUrl(this.projectId, this.url);

    safelyAddSource(this.map, this.id, {
      type: 'image',
      url,
      coordinates: this.coordinates
    });

    safelyAddLayer(this.map, {
      id: this.id,
      'type': 'raster',
      'source': this.id,
      'paint': {
        'raster-fade-duration': 0
      }
    });
  }

  remove(): void {
    safelyRemoveLayer(this.map, this.id);
    safelyRemoveSource(this.map, this.id);
    this.map = undefined;
  }

  layerIds(): string[] {
    return [this.id];
  }
}
