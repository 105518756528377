import { createSelector } from 'reselect';

import { FormsSupportState } from './formsSupport.reducer';

export const getFormsSupportCache = (state: FormsSupportState) => state.cache;
export const getFormsSupportAttributes = (state: FormsSupportState) => state.attributes;
export const getFormsSupportFormCache = (id: string) => createSelector(getFormsSupportCache, cache => cache?.[id]);
export const getFormsSupportGeometrySettings = (state: FormsSupportState) => state.geometrySetings;
export const getFormsSupportAttributeState = <T = any>(id: string) => createSelector(getFormsSupportAttributes, attributes => attributes?.[id] ?? {} as T);
export const getFormsSupportRestoreMediaAttributeId = (state: FormsSupportState) => state.restoreMediaAttributeId;
export const getFormsSupportRestoreMediaAttachmentId = (state: FormsSupportState) => state.restoreMediaAttachmentId;
export const getFormsSupportRestoreMediaUrl = (state: FormsSupportState) => state.restoreMediaUrl;
