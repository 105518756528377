import { store } from '@stencil/redux';
import { getAppConfigApiUrl, getAppEnvironment } from '../store/selectors';
import { CONFIGS } from '../config';

const validOutputs = new Set([
  "auto",
  "av1",
  "avif",
  "blank",
  "blurhash",
  "h264",
  "h265",
  "heif",
  "image",
  "jpeg",
  "jxl",
  "main",
  "mean",
  "png",
  "preview",
  "sketch",
  "vp9",
  "webp",
]);

const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

const transformProperties = (params) => {
  const transformed = [];
  if (params.w && isNumeric(params.w)) {
    transformed.push(
      `resize=${params.w}` +
        (params.h && isNumeric(params.h) ? `x${params.h}` : "")
    );
  } else if (params.output && validOutputs.has(params.output)) {
    transformed.push(`output=${params.output}`);
  }
  return transformed.join("/");
};

export const imageToImageProxy = (url: string, params: any = {}) => {
	const state = store.getState();
    const environment = getAppEnvironment(state);
  const targetUrl = CONFIGS[environment].IMAGES_PROXY_ENABLED
    ? getAppConfigApiUrl(state) + "/images"
        : CONFIGS[environment].IMAGES_CDN_URL

	if (!targetUrl) {
		return url;
	}

	let proxyUrl: string = url;
    if (url?.startsWith('https://coreo.s3')) {
      let qry: string;
        const p = new URL(url);
        proxyUrl = `${targetUrl}${p.pathname}`;
        if (!CONFIGS[environment].IMAGES_PROXY_ENABLED) {
            const transformedProperties = transformProperties(params);
            qry = `?twic=v1${transformedProperties ? '/' + transformedProperties : ''}`;
        } else {
            qry = new URLSearchParams(params).toString();
        }
        proxyUrl += qry;
    }
	return proxyUrl;
}


export const cdnUrl = (string) => {
  try {
    const state = store.getState();
    const environment = getAppEnvironment(state);
    const s3Url = new URL(string);
    if (!s3Url.hostname.startsWith('coreo')) {
      return string;
    }
    return CONFIGS[environment].CDN_URL + s3Url.pathname;
  } catch (e) {
    console.warn(`Failed to translate image URL: "${string}"`);
    return string;
  }
}
