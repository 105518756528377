import { capSQLiteSet } from "@capacitor-community/sqlite";
import bbox from "@turf/bbox";
import { BBox2d } from "@turf/helpers/dist/js/lib/geojson";
import EventEmitter from "eventemitter3";
import { CoreoApp, CoreoAppSummary, CoreoAssociateMap, CoreoAttribute, CoreoAttributeType, CoreoCollectionItem, CoreoGeometry, CoreoRecord, CoreoRecordAttachment, CoreoRecordFilter, CoreoRecordSyncStatus } from "../../types";
import { CoreoAPI } from "../api.service";
import { imageToImageProxy } from "../image.service";
import { uploadAttachment } from "../records.service";
import Sentry from "../sentry.service";
import { AppSyncResource, SYNC_REQUEST_BATCH_SIZE, SYNC_WRITE_SQL_BUFFER_SIZE, SyncItem, SyncProject, SyncRecord, SyncUser } from "./app-sync.service";
import { initProjectFilesystem, storeProjectIcon, writeProjectFile } from "./filesystem.service";
import { AppDatabaseAssociation, AppDatabaseAssociationRepo } from "./models/association.db";
import { AppDatabaseAttachment, AppDatabaseAttachmentRepo } from "./models/attachment.db";
import { AppDatabaseAttributeRepo } from "./models/attribute.db";
import { AppDatabaseTable } from "./models/base.db";
import { AppDatabaseCollectionRepo } from "./models/collection.db";
import { AppDatabaseFormRepo } from "./models/form.db";
import { AppDatabaseItemRepo } from "./models/item.db";
import { AppDatabaseMapLayerRepo } from "./models/map-layer.db";
import { AppDatabaseMediaItemRepo } from "./models/media-item.db";
import { AppDatabasePageRepo } from "./models/page.db";
import { AppDatabaseProjectRepo } from "./models/project.db";
import { AppDatabaseRecordRepo } from "./models/record.db";
import { AppDatabaseStateRepo } from "./models/state.db";
import { AppDatabaseUserRepo } from "./models/user.db";
import SQLDatabase, { SQLSelect, Transaction, TransactionQueryInterface } from "./sql.service";

export enum AppDatabaseSyncError {
  'FREE_TRIAL_EXPIRED',
  'ACCESS_DENIED',
  'UPLOAD_ERROR',
  'USER_ABORTED',
  'UNKNOWN'
};

type AppDatabaseEventTypes = {
  syncStart: (id: number, installation: boolean) => void;
  syncProgress: (step: ProjectSyncStep, status: ProjectSyncStepState, progress?: number) => void;
  syncDidUpdate: (id: number, records: boolean, config: boolean) => void;
  syncComplete: (id: number, app: CoreoAppSummary) => void;
  syncError: (e: ProjectSyncError) => void;
  syncRecordIdUpdate: (oldId: number, newId: number) => void;
}


export class ProjectFreeTrialExpiredError extends Error {
  constructor() {
    super();
    this.name = 'ProjectFreeTrialExpiredError';
  }
}

export class ProjectAccessDeniedError extends Error {
  constructor() {
    super();
    this.name = 'ProjectAccessDeniedError';
  }
}

export class ProjectUnknownError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ProjectUnknownError';
  }
}

export class ProjectUploadError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ProjectUploadError';
  }
}

export class ProjectUserAbortError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ProjectUserAbortError';
  }
}

export class ProjectNotFoundError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ProjectNotFoundError';
  }
}

export class ProjectDeletedError extends Error {
  constructor(public date: string) {
    super();
    this.name = 'ProjectDeletedError';
  }
}

export type ProjectSyncError = |
  ProjectAccessDeniedError |
  ProjectFreeTrialExpiredError |
  ProjectUnknownError |
  ProjectNotFoundError |
  ProjectDeletedError |
  ProjectUserAbortError;


export enum ProjectSyncStep {
  CONFIG,
  USERS,
  RECORDS,
  UPLOAD
};

export enum ProjectSyncStepState {
  NOT_STARTED,
  RUNNING,
  COMPLETE,
  ERROR,
  CANCELLED
};

export interface ProjectSyncState {
  config: ProjectSyncStepState;
  users: ProjectSyncStepState;
  records: ProjectSyncStepState;
  upload: ProjectSyncStepState;
}

interface ProjectSyncCheckResponse {
  project: number;
  records: number;
}

interface ProjectSyncOptions {
  force?: boolean;
  step?: ProjectSyncStep;
}

const parseMultiSelect = (json: string) => {
  try {
    const result = JSON.parse(json);
    if (Array.isArray(result)) {
      return result;
    }
    return json.split(',');
  } catch (e) {
    return json.split(',');
  }
}

export default class AppDatabase {

  // A map of attributes by id
  private attributeMap: Map<number, CoreoAttribute> = new Map<number, CoreoAttribute>();
  private attributeTypeMap: Map<string, CoreoAttributeType> = new Map<string, CoreoAttributeType>();

  // Stores a list of attribute paths for each form - used to build the insert data
  public formDataColumnMap: Map<number, string[]> = new Map<number, string[]>();

  public static readonly DB_SAFE_RANGE = 100000;

  public static instance: AppDatabase = new AppDatabase();
  public events: EventEmitter<AppDatabaseEventTypes> = new EventEmitter<AppDatabaseEventTypes>();

  public static isNewRecord(record: CoreoRecord): boolean {
    return record.id < this.DB_SAFE_RANGE;
  }

  public records: AppDatabaseRecordRepo = new AppDatabaseRecordRepo();
  public attachments: AppDatabaseAttachmentRepo = new AppDatabaseAttachmentRepo();
  public items: AppDatabaseItemRepo = new AppDatabaseItemRepo();
  public users: AppDatabaseUserRepo = new AppDatabaseUserRepo();
  public associations: AppDatabaseAssociationRepo = new AppDatabaseAssociationRepo();
  public projects: AppDatabaseProjectRepo = new AppDatabaseProjectRepo();
  public mediaItems: AppDatabaseMediaItemRepo = new AppDatabaseMediaItemRepo();
  public pages: AppDatabasePageRepo = new AppDatabasePageRepo();
  public attributes: AppDatabaseAttributeRepo = new AppDatabaseAttributeRepo();
  public collections: AppDatabaseCollectionRepo = new AppDatabaseCollectionRepo();
  public forms: AppDatabaseFormRepo = new AppDatabaseFormRepo();
  public states: AppDatabaseStateRepo = new AppDatabaseStateRepo();
  public mapLayers: AppDatabaseMapLayerRepo = new AppDatabaseMapLayerRepo();

  private itemCache: Map<number, Map<string, CoreoCollectionItem>> = new Map<number, Map<string, CoreoCollectionItem>>();

  private syncAbortController: AbortController;

  private filterStandardColumns(attributes: CoreoAttribute[]) {
    return attributes.filter(attribute => {
      if (!attribute.surveyId) {
        return false;
      }
      if (['attachment'].includes(attribute.type)) {
        return false;
      }
      if (['geometry', 'text', 'child', 'association'].includes(attribute.questionType)) {
        return false;
      }
      return true;
    });
  }

  public async init(): Promise<void> {
    await this.createTables();
  }

  private async createTables() {
    const result = await Promise.all([
      this.projects.initTable(),
      this.pages.initTable(),
      this.attributes.initTable(),
      this.collections.initTable(),
      this.forms.initTable(),
      this.states.initTable(),
      this.mapLayers.initTable(),
      this.records.initTable(),
      this.attachments.initTable(),
      this.associations.initTable(),
      this.users.initTable(),
      this.items.initTable(),
      this.mediaItems.initTable(),
    ]);
    const operations = await result.flat();
    if (operations.length > 0) {
      await SQLDatabase.instance.executeSet(operations);
    }
  }

  public async saveRecord(parentRecord: CoreoRecord): Promise<void> {

    const associatesStatement = `INSERT OR REPLACE INTO associations
    (sourceId,targetId,attributeId,projectId) VALUES (?,?,?,?)`;

    const now = new Date();
    const timestamp = now.valueOf();
    const updatedAt = now.toISOString();
    const projectId = parentRecord.projectId;

    const transaction = await SQLDatabase.instance.transaction();

    // return SQLDatabase.instance.transaction(async (execute, query) => {

    // Returns the next available ID within the context of the transaction
    const nextId = async (): Promise<number> => {
      const q = this.selectQueryBuilder('records')
        .field('MAX(id)', 'id')
        .where('id < ?', AppDatabase.DB_SAFE_RANGE);

      const result = await transaction.query<{ id: number }>({
        statement: q.toString(),
        values: []
      });

      if (result && result.length > 0) {
        return result[0].id + 1;
      }
      return 1;
    }

    const doInsert = async (r: CoreoRecord) => {

      // Add the top-level record
      const id = r.id === null ? await nextId() : r.id;
      const resultSet = this.records.toInsertSet({
        ...r,
        id,
        timestamp,
        userId: parentRecord.userId,
        state: parentRecord.state,
        createdAt: parentRecord.createdAt,
        updatedAt
      });
      const result = await transaction.executeSet(resultSet);

      // Clear out the attachments and associations for this record
      await transaction.execute({
        statement: this.deleteQueryBuilder('attachments')
          .where('recordId = ? ', r.id).toString(),
        values: []
      });

      await transaction.execute({
        statement: this.deleteQueryBuilder('associations')
          .where('sourceId = ? ', r.id).toString(),
        values: []
      });

      // Handle any attachments
      for (const attachment of r.attachments ?? []) {
        await transaction.executeSet(this.attachments.toInsertSet({
          ...attachment,
          recordId: id,
          projectId,
          size: attachment.size
        }));
      }

      // Add associated records
      for (const attributeId in r.associates) {
        const associationAttribute = this.attributeMap.get(+attributeId);

        for (const associate of r.associates[attributeId]) {
          const associateRecordId = associationAttribute.questionType === 'association' ? associate.id : await doInsert(associate);
          await transaction.execute({
            statement: associatesStatement,
            values: [
              id,
              associateRecordId,
              +attributeId,
              projectId
            ]
          });
        }
      }

      return result.changes.lastId;
    };

    // Start off the process
    try {
      await doInsert(parentRecord)
      await transaction.commit();
      this.events.emit('syncDidUpdate', projectId, true, false);
    } catch (e) {
      console.warn(e);
      Sentry.captureException(e, {
        extra: {
          record: parentRecord
        }
      });
      await transaction.rollback();
    }
  }

  public deleteRecordSet(id: number): capSQLiteSet[] {
    return [
      {
        statement: this.deleteQueryBuilder('records').where('id = ?', id).toString(),
        values: []
      },
      {
        statement: this.deleteQueryBuilder('attachments').where('recordId = ?', id).toString(),
        values: []
      },
      {
        statement: this.deleteQueryBuilder('associations').where(
          SQLDatabase.expr().and('sourceId = ?', id).or('targetId = ?', id)).toString(),
        values: []
      }
    ]
  }

  public selectQueryBuilder(alias: AppDatabaseTable) {
    return SQLDatabase.select().from(alias);
  }

  public updateQueryBuilder(alias: AppDatabaseTable) {
    return SQLDatabase.update().table(alias);
  }

  public deleteQueryBuilder(alias: AppDatabaseTable) {
    return SQLDatabase.delete().from(alias);
  }

  async findAttachments(recordId: number): Promise<CoreoRecordAttachment[]> {
    const query = this.selectQueryBuilder('attachments')
      .where('recordId = ?', recordId);
    return await SQLDatabase.instance.query(query);
  }

  async findAssociates(recordId: number, direction: 'source' | 'target' = 'source'): Promise<CoreoAssociateMap> {
    const result = {};
    const query = this.selectQueryBuilder('associations')
      .where(
        // SQLDatabase.expr().and("sourceId = ?", recordId).or("targetId = ?", recordId)
        SQLDatabase.expr().and(direction === 'source' ? "sourceId = ?" : "targetId = ?", recordId)
      );
    const associates = await SQLDatabase.instance.query<AppDatabaseAssociation>(query);

    for (const associate of associates) {
      // If this record is the target of the association,
      const associateId = associate.targetId === recordId ? associate.sourceId : associate.targetId;
      const associateRecord = await this.records.findById(associateId);
      if (associateRecord) {
        result[associate.attributeId] = result[associate.attributeId] || [];
        result[associate.attributeId].push(associateRecord);
      }
    }
    return result;
  }

  async updateAttachmentUrl(attachmentId: number, url: string) {
    const query = this.updateQueryBuilder('attachments')
      .where('id = ?', attachmentId)
      .set('url', url);
    return SQLDatabase.instance.query(query);
  }

  private async loadRecordData(id: number, formId: number) {
    const raw = await this.records.getRecordData(id, formId);

    const data = Object.fromEntries<any>(Object.entries<any>(raw).filter(([_, v]) => v !== null));

    for (const d in data) {
      const type = this.attributeTypeMap.get(d);
      switch (type) {
        case 'multiselect': {
          data[d] = data[d]?.length > 0 ? parseMultiSelect(data[d]) : [];
          break;
        }
        case 'boolean': {
          data[d] = Boolean(parseInt(data[d]));
          break;
        }
        case 'integer': {
          data[d] = parseInt(data[d]);
          break;
        }
        case 'float': {
          data[d] = parseFloat(data[d]);
          break;
        }
      }
    }
    return data;
  }

  async loadRecord(id: number): Promise<CoreoRecord> {
    // Load the base record
    const record = await this.records.findById(id);

    // Load the attachments
    // record.attachments = await this.findAttachments(id);
    record.attachments = await this.attachments.query({ recordId: id });
    // Load the user
    record.user = await this.users.findById(record.userId);

    // Load the data
    record.data = await this.loadRecordData(id, record.formId);

    // Load the associations
    const associations = await this.findAssociates(id);

    for (const a in associations) {
      for (let i = 0; i < associations[a].length; i++) {
        associations[a][i] = await this.loadRecord(associations[a][i].id);
      }
    }
    record.associates = associations;
    return record;
  }

  public async getCollectionBounds(collectionId: number, projectId: number): Promise<BBox2d> {

    const features: CoreoGeometry[] = [];

    for await (const batch of this.items.search(q => q.where('collectionId = ?', collectionId).where('projectId = ?', projectId).where('geometry IS NOT NULL'), 1000)) {
      for (const item of batch) {
        features.push(item.geometry);
      }
    }

    return bbox({
      type: 'FeatureCollection',
      features: features.map(f => ({ type: 'Feature', properties: {}, geometry: f }))
    }) as BBox2d;
  }

  public async getCollectionItem(collectionId: number, projectId: number, key: string): Promise<CoreoCollectionItem> {

    const cached = this.itemCache.get(collectionId)?.get(key);
    if (cached) {
      return cached;
    }

    if (!this.itemCache.has(collectionId)) {
      this.itemCache.set(collectionId, new Map<string, CoreoCollectionItem>());
    }
    const item = await this.items.queryOne({ collectionId, projectId, key });

    this.itemCache.get(collectionId).set(key, item);
    return item;
  }

  private async getLastIdAndTimestamp(projectId: number, table: AppDatabaseTable, query: TransactionQueryInterface, filter: { [key: string]: any } = {}): Promise<[number, number]> {
    const lastQuery = this.selectQueryBuilder(table)
      .field('timestamp')
      .field('id')
      .limit(1)
      .where('projectId = ? ', projectId)
      .order('timestamp', false)
      .order('id', false);

    for (const f in filter) {
      lastQuery.where(`${f} = ?`, filter[f]);
    }

    const last = await query<{ id: number; timestamp: number }>({
      statement: lastQuery.toString(),
      values: []
    });

    if (last.length > 0) {
      return [last[0].id, last[0].timestamp];
    };
    return [0, 0];
  }

  private async getLastRecordIdAndTimestamp(projectId: number, query: TransactionQueryInterface): Promise<[number, number]> {
    const queryObj = this.selectQueryBuilder('projects')
      .where('id = ?', projectId)
      .field('lastRecordId')
      .field('lastRecordTimestamp');

    const result = await query<{ lastRecordId: number; lastRecordTimestamp: number }>({
      statement: queryObj.toString(),
      values: []
    });

    return [result[0].lastRecordId, result[0].lastRecordTimestamp];
  }

  public async getAllPendingUpdates(): Promise<CoreoRecord[]> {
    return this.records.search(query => query.where('syncState = ? ', 1)).toArray();
  }

  public applyCoreoFilterToQuery(query: SQLSelect, filter: CoreoRecordFilter): SQLSelect {
    if (filter.userId) {
      query.where('userId = ?', filter.userId);
    }
    if (filter.forms.length > 0) {
      query.where('surveyId IN ?', filter.forms);
    }
    if (filter.states.length > 0) {
      query.where('state IN ?', filter.states);
    }
    if (filter.from) {
      query.where('createdAt >= ?', filter.from);
    }
    if (filter.to) {
      query.where('createdAt <= ?', filter.to);
    }
    return query;
  }

  public async pendingError(id: number, error: string, context: Record<string, unknown> = {}): Promise<void> {

    const statement = this.updateQueryBuilder('records')
      .where('id = ?', id)
      .set("syncError", error);
    await SQLDatabase.instance.execute(statement);

    Sentry.withScope(scope => {
      scope.setContext("uploadError", {
        id,
        error,
        ...context
      });
      Sentry.captureMessage("Sync Record Error");
    });
  }

  public async resolvePendingUpdate(id: number, current: CoreoRecord): Promise<void> {
    const operations: capSQLiteSet[] = [];

    operations.push({
      statement: this.updateQueryBuilder('records')
        .where('id = ?', id)
        .set("id", current.id)
        .set("syncState", CoreoRecordSyncStatus.SUBMITTED)
        .set("syncError", null)
        .set("updatedAt", current.updatedAt)
        .set("timestamp", current.timestamp)
        .set("userId", current.userId)
        .toString(),
      values: []
    });

    const dataUpdate = SQLDatabase.update().table('records_' + current.surveyId)
      .set('id', current.id)
      .where('id = ?', id);

    for (const p in current.data) {
      const update = Array.isArray(current.data[p]) ? JSON.stringify((current.data[p] as any)) : current.data[p];
      dataUpdate.set(p, update);
    }
    const { text: statement, values } = dataUpdate.toParam();

    operations.push({
      statement,
      values
    });

    if (id !== current.id) {
      // Shouldn't have to do this?
      const associateUpdateA = this.updateQueryBuilder('associations')
        .set("sourceId", current.id)
        .where("sourceId = ?", id);
      operations.push({
        statement: associateUpdateA.toString(),
        values: []
      });

      const associateUpdateB = this.updateQueryBuilder('associations')
        .set("targetId", current.id)
        .where("targetId = ?", id);
      operations.push({
        statement: associateUpdateB.toString(),
        values: []
      });

      const associateUpdateC = this.updateQueryBuilder('attachments')
        .set("recordId", current.id)
        .where("recordId = ?", id);
      operations.push({
        statement: associateUpdateC.toString(),
        values: []
      });
    }

    operations.push({
      statement: 'UPDATE projects SET lastRecordId = ?, lastRecordTimestamp = ? WHERE id = ? AND (lastRecordId < ? OR lastRecordTimestamp < ?)',
      values: [
        current.id,
        current.timestamp,
        current.projectId,
        current.id,
        current.timestamp
      ]
    });

    await SQLDatabase.instance.executeSet(operations);
  }

  public async getProjectFromAPI(id: number, signal?: AbortSignal): Promise<SyncProject> {
    const result = await CoreoAPI.instance.get(`/sync/${id}`, { authentication: true, signal });
    if (result.ok) {
      return await result.json() as SyncProject;
    } else if (result.status === 401) {
      throw new ProjectAccessDeniedError();
    }
    throw new ProjectUnknownError(result.statusText);
  };

  // Returns true if an update is required
  public async syncCheck(id: number, signal: AbortSignal, query: TransactionQueryInterface): Promise<ProjectSyncCheckResponse> {

    // Need to get lastId, lastUpdatedAt and the project rev
    // transaction ??= await SQLDatabase.instance.transaction();
    const [lastId, lastUpdatedAt] = await this.getRecordSyncPoint(id, query);
    const rev = await this.getProjectRev(id, query);

    const url = `/sync/${id}/check?lastId=${lastId}&lastUpdatedAt=${lastUpdatedAt}&rev=${rev}`;
    const response = await CoreoAPI.instance.get(url, {
      authentication: true,
      signal
    });

    if (!response.ok) {
      if (response.status === 410) {
        const d: { deletedAt: string } = await response.json();
        throw new ProjectDeletedError(d.deletedAt);
      }
      if (response.status === 404) {
        throw new ProjectNotFoundError('Project not found');
      }
      return null;
    }

    const result: ProjectSyncCheckResponse = await response.json();
    return result;
  }

  public async projectToSQLSet(project: SyncProject, transaction: Transaction): Promise<capSQLiteSet[]> {
    const set: capSQLiteSet[] = [];
    const projectId = project.id;

    set.push(...this.projects.toInsertSet(project));

    // Delete and re-add attributes
    set.push(...this.attributes.toDeleteSet(projectId));
    for (const attribute of project.attributes) {
      set.push(...this.attributes.toInsertSet({
        ...attribute,
        projectId
      }));
    }

    const formIds = project.surveys.map(s => s.id).join(',');
    const deletedForms = await transaction.query<{ id: number }>({
      statement: `SELECT id FROM forms WHERE projectId = ${projectId} AND id NOT IN (${formIds})`,
      values: []
    });

    for (const deletedForm of deletedForms ?? []) {
      set.push({
        statement: 'DELETE FROM records WHERE surveyId = ? AND projectId = ?',
        values: [deletedForm.id, project.id]
      }, {
        statement: 'DELETE FROM forms WHERE id = ? AND projectId = ?',
        values: [deletedForm.id, project.id]
      });
    }

    for (const form of project.surveys) {

      set.push(...this.forms.toInsertSet({
        ...form,
        projectId
      }));
      const tableColumns = await SQLDatabase.instance.tableColumns(`records_${form.id}`);
      const formAttributes = project.attributes.filter(a => {
        if (a.surveyId !== form.id) {
          return false;
        }
        if (['attachment'].includes(a.type)) {
          return false;
        }
        if (['geometry', 'text', 'child', 'association'].includes(a.questionType)) {
          return false;
        }
        return true;
      });

      // If we are creating the table
      if (tableColumns.length === 0) {
        const attributes = formAttributes.length > 0 ? (', ' + formAttributes.map(a => `"${a.path}" TEXT`).join(',')) : '';
        set.push({
          statement: `CREATE TABLE IF NOT EXISTS records_${form.id} (id INTEGER PRIMARY KEY NOT NULL ${attributes});`,
          values: []
        });
      }
      else {
        // Add missing columns
        for (const formAttribute of formAttributes) {
          if (typeof tableColumns.find(a => a.name === formAttribute.path) === 'undefined') {
            set.push({
              statement: `ALTER TABLE "records_${form.id}" ADD COLUMN "${formAttribute.path}" TEXT`,
              values: []
            })
          }
        }

        // Now clear any columns that we shouldn't have
        // On Android (versions of sqlite < 3.35.0), we can't drop columns, so we have to set them to null
        // https://www.sqlite.org/changes.html
        for (const tableColumn of tableColumns.filter(t => t.name !== 'id')) {
          if (typeof formAttributes.find(f => f.path === tableColumn.name) === 'undefined') {
            set.push({
              statement: `UPDATE "records_${form.id}" SET "${tableColumn.name}" = NULL`,
              values: []
            });
          }
        }
      }
    }

    // Delete any attachments that should no longer be here
    const attachmentAttributes = project.attributes.filter(a => ['attachment', 'media'].includes(a.type));
    const attachmentDelete = attachmentAttributes.length === 0 ? '' : `AND attributeId NOT IN (${attachmentAttributes.map(a => a.id).join(',')})`;
    const attachmentDeleteStatement = `DELETE FROM attachments WHERE projectId = ? ${attachmentDelete}`;

    set.push({
      statement: attachmentDeleteStatement,
      values: [projectId]
    });

    // Delete any associations that should no longer be here
    const associationAttributes = project.attributes.filter(a => ['association', 'child'].includes(a.questionType));
    const associationDelete = associationAttributes.length === 0 ? '' : `AND attributeId NOT IN (${associationAttributes.map(a => a.id).join(',')})`;
    const associationDeleteStatement = `DELETE FROM associations WHERE projectId = ? ${associationDelete}`;

    set.push({
      statement: associationDeleteStatement,
      values: [projectId]
    });

    // Delete and re-add collections
    set.push(...this.collections.toDeleteSet(projectId));
    for (const collection of project.collections) {
      set.push(...this.collections.toInsertSet({
        ...collection,
        projectId
      }));
    }

    // Delete and re-add pages
    set.push(...this.pages.toDeleteSet(projectId));
    for (const page of project.pages) {
      set.push(...this.pages.toInsertSet({
        ...page,
        projectId
      }));

      for (const mediaItem of page.mediaItems) {
        set.push(...this.mediaItems.toInsertSet({
          ...mediaItem,
          projectId,
          itemId: null,
          pageId: page.id
        }));
      }
    }

    // Delete and re-add states
    set.push(...this.states.toDeleteSet(projectId));
    for (const state of project.states) {
      set.push(...this.states.toInsertSet({
        ...state,
        projectId
      }))
    }

    // Delete and re-add maps and map layers
    set.push(...this.mapLayers.toDeleteSet(projectId));

    for (const layer of project.maps) {
      set.push(...this.mapLayers.toInsertSet({
        ...layer,
        projectId
      }));
    }

    return set;
  };

  private async installProject(projectId: number, transaction: Transaction, signal: AbortSignal): Promise<CoreoApp> {

    let project: SyncProject = await this.getProjectFromAPI(projectId, signal);
    signal.throwIfAborted();

    if (project.freeTrialExpired) {
      throw new ProjectFreeTrialExpiredError();
    }

    let set = await this.projectToSQLSet(project, transaction);
    signal.throwIfAborted();

    await transaction.executeSet(set, SYNC_WRITE_SQL_BUFFER_SIZE);
    signal.throwIfAborted();

    const projectApp: CoreoApp = {
      id: project.id,
      name: project.name,
      description: project.description,
      forms: project.surveys,
      states: project.states,
      attributes: project.attributes,
      pages: project.pages,
      slug: project.slug,
      bounds: project.bounds,
      collections: project.collections,
      icon: project.icon,
      membership: project.membership,
      welcomePageId: project.welcomePageId,
      hideUsernames: project.hideUsernames,
      css: project.css,
      features: project.features,
      maps: project.maps
    };
    return projectApp;
  }

  private async cacheProjectConfig(app: CoreoApp) {
    const { forms, attributes } = app;


    this.formDataColumnMap.clear();
    for (const form of forms) {
      const formAttributes = this.filterStandardColumns(attributes.filter(a => a.surveyId === form.id))
        .filter(a => !!a.path)
        .map(a => a.path);
      this.formDataColumnMap.set(form.id, formAttributes);
    }

    this.attributeMap.clear();
    this.attributeTypeMap.clear();

    for (const attribute of attributes) {
      this.attributeMap.set(attribute.id, attribute);
      this.attributeTypeMap.set(attribute.path, attribute.type);
    }
  }

  public async loadProject(projectId: number): Promise<CoreoApp> {

    const results = await Promise.all([
      this.projects.findById(projectId),
      this.forms.query({ projectId }),
      this.attributes.query({ projectId }),
      this.pages.query({ projectId }),
      this.collections.query({ projectId }),
      this.states.query({ projectId }),
      this.mapLayers.query({ projectId })
    ]);

    const [projectBase, forms, attributes, pages, collections, states, maps] = results;

    if (projectBase === null) {
      throw Error('Project not found');
    }

    const project: CoreoApp = {
      ...projectBase,
      forms,
      attributes,
      states,
      pages,
      collections,
      maps
    };

    this.itemCache.clear();
    await this.cacheProjectConfig(project);

    return project;
  }

  private async getRecordSyncPoint(id: number, transactionQuery: TransactionQueryInterface): Promise<number[]> {
    // Get the last ID/Timestamp from the project
    const [lastId, lastUpdatedAt] = await this.getLastRecordIdAndTimestamp(id, transactionQuery);
    if (lastId !== null) {
      return [lastId, lastUpdatedAt];
    }
    // If there isn't one, get it from the DB records
    return this.getLastIdAndTimestamp(id, 'records', transactionQuery, { syncState: 0 });
  }

  private async getProjectRev(id: number, query: TransactionQueryInterface): Promise<number> {
    const result = await query<{ rev: number }>({
      statement: "SELECT rev FROM projects WHERE id = ?",
      values: [id]
    });
    return result[0]?.rev ?? 0;
  }

  public async syncRecords(id: number, transaction: Transaction, signal: AbortSignal, force: boolean, skipDeletes: boolean = false): Promise<CoreoRecord[]> {

    const [lastId, lastUpdatedAt] = await this.getRecordSyncPoint(id, transaction.query);

    // First find all pending records
    const updates = await this.records.getPendingUpdateIds(id);

    const resource = new AppSyncResource<SyncRecord>(id, 'records', lastId ?? 0, lastUpdatedAt ?? 0, SYNC_REQUEST_BATCH_SIZE, force, signal);
    const operations: capSQLiteSet[] = [];
    const conflicts: CoreoRecord[] = [];

    let total: number;
    let lastRecord: SyncRecord;

    const flushBuffer = async (minBufferSize = 0) => {
      signal?.throwIfAborted();
      if (operations.length > 0 && (operations.length > minBufferSize)) {
        await transaction.executeSet(operations);
        operations.length = 0;
      }
    };

    const downloadedAttachments = force ? [] : await transaction.query<AppDatabaseAttachment>({
      statement: 'SELECT recordId, url, fileLocation FROM attachments WHERE projectId = ? AND fileLocation IS NOT NULL',
      values: [id]
    });

    for await (const batch of resource) {
      if (typeof total === 'undefined') {
        total = batch.result.length + batch.remaining;
      }
      this.events.emit('syncProgress', ProjectSyncStep.RECORDS, ProjectSyncStepState.RUNNING, (total - batch.remaining) / total);

      for (const record of batch.result) {
        // Update the last record pointer
        lastRecord = record;

        // If we have a conflict, note it and move on
        if (updates.includes(record.id)) {
          conflicts.push(record);
          continue;
        }

        // If this is a deleted record and we're skipping deletes, continue
        if (skipDeletes && record.deletedAt) {
          continue;
        }

        if (record.deletedAt) {
          if (!force) {
            operations.push(...this.deleteRecordSet(record.id));
          }
          continue;
        }

        // Delete associations and attachments for this record
        if (!force) {
          operations.push(...this.associations.toDeleteSetForRecord(record.id));
          operations.push(...this.attachments.toDeleteSetForRecord(record.id));
        }

        operations.push(...this.records.toInsertSet({
          ...record,
          projectId: id
        }));

        for (const attachment of record.attachments) {
          const existing = downloadedAttachments.find(a => a.recordId === record.id && a.url === attachment.url);

          operations.push(...this.attachments.toInsertSet({
            url: attachment.url,
            mimeType: attachment.mimeType,
            recordId: record.id,
            projectId: id,
            attributeId: attachment.attributeId,
            size: attachment.size,
            fileLocation: existing?.fileLocation ?? null
          }));
        }

        for (const association of record.sourceAssociations) {
          operations.push(...this.associations.toInsertSet([
            association.sourceId,
            association.targetId,
            association.attributeId,
            id
          ]));
        }

        await flushBuffer(SYNC_WRITE_SQL_BUFFER_SIZE);
      }
    }

    // Update our records pointer on the project
    if (lastRecord) {
      const projectUpdate = this.updateQueryBuilder('projects')
        .where('id = ?', id)
        .set('lastRecordId', lastRecord.id)
        .set('lastRecordTimestamp', lastRecord.timestamp);

      operations.push({
        statement: projectUpdate.toString(),
        values: []
      });
    }
    // Flush this and any remaining operations
    await flushBuffer();

    // If this was a force sync, the initial sync process would have ignored any deleted records
    // So we run another sync process now, but indicate that we can skip over applying deletes to
    // the database - this process is really just to ensure our lastRecordId and lastRecordTimestamp
    // fields are up to date
    if (force) {
      return this.syncRecords(id, transaction, signal, false, true);
    }

    console.log('CONFLICTS', conflicts);
    return conflicts;
  }

  private async syncUsers(id: number, transaction: Transaction, signal: AbortSignal, force: boolean) {
    let [lastId, lastUpdatedAt] = await this.getLastIdAndTimestamp(id, 'users', transaction.query);
    const resource = new AppSyncResource<SyncUser>(id, 'users', lastId, lastUpdatedAt, SYNC_REQUEST_BATCH_SIZE, force, signal);
    const operations: capSQLiteSet[] = [];

    for await (const batch of resource) {
      for (const user of batch.result) {
        operations.push(...this.users.toInsertSet({
          ...user,
          projectId: id
        }));
      }
    }

    if (operations.length > 0 && !signal.aborted) {
      await transaction.executeSet(operations, SYNC_WRITE_SQL_BUFFER_SIZE);
    }

  }

  private async syncItems(id: number, transaction: Transaction, signal: AbortSignal, force: boolean) {

    let [lastId, lastUpdatedAt] = await this.getLastIdAndTimestamp(id, 'items', transaction.query);
    const resource = new AppSyncResource<SyncItem>(id, 'items', lastId, lastUpdatedAt, SYNC_REQUEST_BATCH_SIZE, force, signal);
    const operations: capSQLiteSet[] = [];

    const flushBuffer = async (bufferSize = 0) => {
      if (operations.length > 0 && (operations.length > bufferSize)) {
        await transaction.executeSet(operations);
        operations.length = 0;
      }
    };

    // If this is a force sync, delete all the media items now
    if (force) {
      operations.push({
        statement: 'DELETE FROM mediaItems WHERE projectId = ? AND itemId IS NOT NULL',
        values: [
          id
        ]
      });
    }

    for await (const batch of resource) {
      for (const item of batch.result) {
        // If this isn't a force sync, delete any existing mediaItems
        if (!force) {
          operations.push({
            statement: 'DELETE FROM mediaItems WHERE projectId = ? AND itemId = ?',
            values: [
              id,
              item.id
            ]
          });
        }

        // Add the item

        operations.push(...this.items.toInsertSet({
          ...item,
          projectId: id
        }));
        // Flush it from our cache
        this.itemCache.get(item.collectionId)?.delete(item.key);
        for (const mediaItem of item.mediaItems) {
          operations.push(...this.mediaItems.toInsertSet({
            ...mediaItem,
            projectId: id,
            itemId: item.id,
            pageId: null
          }));
        }

        // If this item has an icon, download it now
        if (item.icon) {
          try {
            const response = await fetch(imageToImageProxy(item.icon));
            const data = await response.blob();
            await writeProjectFile(id, data, 'items', item.id);
          } catch (e) {
            console.warn(e);
          }
        }
        await flushBuffer(SYNC_WRITE_SQL_BUFFER_SIZE);
      }
    }
    await flushBuffer();
  }

  public async uploadRecords(id: number, signal: AbortSignal): Promise<Map<number, number>> {
    const updates = await this.records.getPendingUpdateIds(id);
    if (updates.length === 0) {
      return null;
    }

    const records: any[] = [];

    for (const recordId of updates) {

      const { syncError, syncState, timestamp, geometryCenter, deletedAt, createdAt, updatedAt, ...record } = await this.records.findById(recordId);
      record.data = await this.loadRecordData(recordId, record.formId);

      const attachments = await this.attachments.query({ recordId });

      for (const attachment of attachments) {
        if (!(attachment.url?.startsWith('http'))) {

          try {
            const url = await uploadAttachment(id, attachment, signal);
            attachment.url = url;
            await this.updateAttachmentUrl(attachment.id, url);
          } catch (e) {
            if (!signal.aborted) {
              this.pendingError(recordId, "Upload attachment failed", {
                attachment,
                recordId
              });
              Sentry.captureException(e);
            }
            throw new ProjectUploadError('Upload attachment failed');
          }
        }
      }

      const associates = await this.associations.query({ sourceId: recordId });

      records.push({
        ...record,
        attachments,
        associates: associates.map(({ sourceId, targetId, attributeId }) => [sourceId, targetId, attributeId])
      });
    }

    const url = `/sync/${id}/records`;
    let result: Response;
    let response: { [id: number]: CoreoRecord };

    try {
      result = await CoreoAPI.instance.post(url, JSON.stringify({
        records
      }), { signal: signal, authentication: true });
      response = await result.json();
    } catch (e) {
      throw new ProjectUploadError(result?.statusText ?? 'Upload Error');
    }

    const output: Map<number, number> = new Map<number, number>();

    for (const id in response) {
      // If this response entry does not have an ID and the result isn't ok, mark this as an error
      if (!response[id].id && !result.ok) {
        const record = records.find(r => r.id === +id);
        const error = (response[id] as any).name ?? 'Unknown Error';

        this.pendingError(+id, error, {
          record,
          error: response[id]
        });

      } else if (result.ok) {
        if (+id !== response[id].id) {
          output.set(+id, response[id].id);
        }
        await this.resolvePendingUpdate(+id, response[id]);
      }
    }

    if (!result.ok) {
      throw new ProjectUploadError(result.statusText);
    }
    return output;

  }

  public async deleteProjectSet(id: number): Promise<capSQLiteSet[]> {

    // const forms = await this.forms.query({ projectId: id });
    const operations: capSQLiteSet[] = [];

    operations.push(...this.associations.toDeleteSet(id));
    operations.push(...this.mediaItems.toDeleteSet(id));
    operations.push(...this.attributes.toDeleteSet(id));
    operations.push(...this.items.toDeleteSet(id));
    operations.push(...this.collections.toDeleteSet(id));
    operations.push(...this.pages.toDeleteSet(id));
    operations.push(...this.attachments.toDeleteSet(id));
    operations.push(...this.records.toDeleteSet(id));
    operations.push(...this.forms.toDeleteSet(id));
    operations.push(...this.states.toDeleteSet(id));
    operations.push(...this.users.toDeleteSet(id));
    operations.push(...this.projects.toDeleteSet(id));

    // for (const form of forms) {
    //   operations.push({
    //     statement: `DROP TABLE IF EXISTS records_${form.id}`,
    //     values: []
    //   });
    // }
    return operations;
  }

  public async deleteProject(id: number): Promise<void> {
    const operations = await this.deleteProjectSet(id);
    return SQLDatabase.instance.executeSet(operations);
  }

  public abortSync() {
    this.syncAbortController?.abort();
    this.syncAbortController = undefined;
  }

  public async syncProject(id: number, options: ProjectSyncOptions = { force: false }): Promise<CoreoApp> {

    const { force } = options;
    let app: CoreoApp;

    let transaction: Transaction;
    let check: ProjectSyncCheckResponse;

    let configSyncRequired = force;
    let recordsSyncRequired = force;

    this.syncAbortController = new AbortController();
    const signal = this.syncAbortController.signal;
    signal.throwIfAborted = signal.throwIfAborted ?? (() => {
      if (signal.aborted) {
        throw new Error('Sync Aborted');
      }
    });

    const runStep = async (step: ProjectSyncStep, fn: () => Promise<void>) => {
      signal.throwIfAborted();
      // If we are only running one step, complete it
      if (options.step && options.step !== step) {
        return this.events.emit('syncProgress', step, ProjectSyncStepState.COMPLETE);
      }

      this.events.emit('syncProgress', step, ProjectSyncStepState.RUNNING);
      try {
        await fn();
        signal.throwIfAborted();
        this.events.emit('syncProgress', step, ProjectSyncStepState.COMPLETE);
      } catch (e) {
        this.events.emit('syncProgress', step, ProjectSyncStepState.ERROR);
        throw signal.aborted ? new ProjectUserAbortError(e.message) : e;
      }
    };

    const configStep = () => runStep(ProjectSyncStep.CONFIG, async () => {
      if (force) {
        const deleteOperations = await this.deleteProjectSet(id);
        signal.throwIfAborted();
        await transaction.executeSet(deleteOperations);
      } else {
        check = await this.syncCheck(id, signal, transaction.query);
        configSyncRequired ||= check.project > 0;
        recordsSyncRequired ||= check.records > 0;
      }

      if (force || check?.project) {
        signal.throwIfAborted();
        app = await this.installProject(id, transaction, signal);

        signal.throwIfAborted();
        await this.cacheProjectConfig(app);
        signal.throwIfAborted();
        await initProjectFilesystem(id);
        signal.throwIfAborted();
        await storeProjectIcon(id, app.icon);
        signal.throwIfAborted();
        await this.syncItems(id, transaction, signal, force);
      }

    });

    const usersStep = () => runStep(ProjectSyncStep.USERS, async () => {
      if (force || check?.records || check?.project) {
        await this.syncUsers(id, transaction, signal, force);
      }
    });

    const recordsStep = () => runStep(ProjectSyncStep.RECORDS, async () => {
      if (force || check?.records || check?.project) {
        await this.syncRecords(id, transaction, signal, force);
      }
    });

    const uploadStep = () => runStep(ProjectSyncStep.UPLOAD, async () => {
      const recordsIdsMap = await this.uploadRecords(id, signal);
      if (recordsIdsMap !== null) {
        recordsSyncRequired = true;
        for (let [oldId, newId] of recordsIdsMap) {
          this.events.emit('syncRecordIdUpdate', oldId, newId);
        }
      }
    });

    try {
      this.events.emit('syncStart', id, force);
      transaction = await SQLDatabase.instance.transaction();

      await configStep();
      await usersStep();
      await recordsStep();

      await transaction?.commit();
      transaction = null;

      await uploadStep();

      if (recordsSyncRequired || configSyncRequired) {
        this.events.emit('syncDidUpdate', id, recordsSyncRequired, configSyncRequired);
      }

      return app;
    } catch (e) {
      const isAbort = e.name === "AbortError" || e instanceof ProjectUserAbortError;

      if (!isAbort) {
        Sentry.captureException(e);
      }

      // let error: AppDatabaseSyncError = AppDatabaseSyncError.UNKNOWN;
      let error: ProjectSyncError = e;
      if (isAbort) {
        error = new ProjectUserAbortError('Sync Aborted');
      } else if (e instanceof ProjectFreeTrialExpiredError) {
        error = e
      } else if (e instanceof ProjectAccessDeniedError) {
        error = e
      } else if (e instanceof ProjectUploadError) {
        error = e
      }

      this.events.emit('syncError', error);

      if (transaction) {
        transaction.rollback();
      } else {
        // If we got here then the transaction was already commited.
        // That probably means the error happened in the upload step
        // We may still have applied some config changes that need to be
        if (recordsSyncRequired || configSyncRequired || error instanceof ProjectUploadError) {
          this.events.emit('syncDidUpdate', id, recordsSyncRequired, configSyncRequired);
        }
      }

      throw error;
    } finally {
      this.events.emit('syncComplete', id, app);
      this.syncAbortController = undefined;
    }

  }
}

(window as any).db = AppDatabase.instance;
