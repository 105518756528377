import { CoreoGeometryType, GeometrySelectorUserSettings } from '../../types';
import { TypeKeys } from '../actions';
import { FormsSupportAttributeState } from './formsSupport.reducer';

/******************************************************************************
 * ACTIONS
 *****************************************************************************/
export interface FormsSupportUpdateGeometryTypeAction {
  type: TypeKeys.FORMS_SUPPORT_UPDATE_GEOMETRY_TYPE;
  formId: number;
  geometryType: CoreoGeometryType;
}

export interface FormsSupportUpdateGeometrySettingsAction {
  type: TypeKeys.FORMS_SUPPORT_UPDATE_GEOMETRY_SETTINGS;
  settings: GeometrySelectorUserSettings;
}

export interface FormsSupportResetGeometrySettingsAction {
  type: TypeKeys.FORMS_SUPPORT_RESET_GEOMETRY_SETTINGS;
}

export interface FormSupportUpdateAttributeAction {
  type: TypeKeys.FORMS_SUPPORT_UPDATE_ATTRIBUTE;
  attributeId: number;
  state: FormsSupportAttributeState;
}

export interface FormSupportSetRestoreMediaAttributeAction {
  type: TypeKeys.FORMS_SUPPORT_SET_RESTORE_MEDIA_ATTRIBUTE;
  attributeId: number;
  attachmentId: number;
}

export interface FormSupportSetRestoreMediaUrlAction {
  type: TypeKeys.FORMS_SUPPORT_SET_RESTORE_MEDIA_URL;
  url: string;
}

export interface FormSupportClearRestoreMediaAction {
  type: TypeKeys.FORMS_SUPPORT_CLEAR_RESTORE_MEDIA;
}

export type FormsSupportActions =
  | FormsSupportUpdateGeometryTypeAction
  | FormsSupportUpdateGeometrySettingsAction
  | FormsSupportResetGeometrySettingsAction
  | FormSupportUpdateAttributeAction
  | FormSupportSetRestoreMediaAttributeAction
  | FormSupportSetRestoreMediaUrlAction
  | FormSupportClearRestoreMediaAction;

/******************************************************************************
 * ACTION CREATORS
 *****************************************************************************/
export const formsSupportUpdateGeometryType = (formId: number, geometryType: CoreoGeometryType) => ({
  type: TypeKeys.FORMS_SUPPORT_UPDATE_GEOMETRY_TYPE,
  formId,
  geometryType
});

export const formsSupportUpdateGeometrySettings = (settings: GeometrySelectorUserSettings) => ({
  type: TypeKeys.FORMS_SUPPORT_UPDATE_GEOMETRY_SETTINGS,
  settings
});

export const formsSupportResetGeometrySettings = () => ({
  type: TypeKeys.FORMS_SUPPORT_RESET_GEOMETRY_SETTINGS
});

export const formsSupportUpdateAttribute = (attributeId: string, state: FormsSupportAttributeState) => ({
  type: TypeKeys.FORMS_SUPPORT_UPDATE_ATTRIBUTE,
  attributeId,
  state
});

export const formsSupportSetRestoreMediaAttribute = (attributeId: number, attachmentId: number) => ({
  type: TypeKeys.FORMS_SUPPORT_SET_RESTORE_MEDIA_ATTRIBUTE,
  attributeId,
  attachmentId
});

export const formsSupportSetRestoreMediaUrl = (url: string) => ({
  type: TypeKeys.FORMS_SUPPORT_SET_RESTORE_MEDIA_URL,
  url
});

export const formsSupportClearRestoreMedia = () => ({
  type: TypeKeys.FORMS_SUPPORT_CLEAR_RESTORE_MEDIA
});
