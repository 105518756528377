import { CoreoDataLineStyle, CoreoDataPointStyle, CoreoDataPolygonStyle, CoreoMapLayer } from "../../types";
import { MapLayer } from "./maps-base-layer";
import { getMapGeoJSONUrl, linePaint, pointPaint, polygonBorderPaint, polygonFillPaint, safelyAddLayer, safelyAddSource } from "./maps.utils";

export class MapGeoJSONLayer extends MapLayer {
  constructor(private config: CoreoMapLayer, private projectId: number) {
    super();
  }

  private createLayerId(type: string): string {
    return `${this.id}-${type}-${this.config.id}`;
  }

  async addTo(map: mapboxgl.Map): Promise<void> {
    this.map = map;
    const data = await getMapGeoJSONUrl(this.projectId, this.config.source);
    const color = this.config.style?.color ?? '#000000';
    const opacity = this.config.style?.opacity ?? 0.8;

    const polygonPaintStyle: CoreoDataPolygonStyle = {
      color,
      contrastColor: color,
      polygonOpacity: opacity,
      polygonBorderColor: '#ffffff',
      polygonBorderOpacity: 1,
      polygonBorderWidth: 1,
    };

    const linePaintStyle: CoreoDataLineStyle = {
      color,
      contrastColor: color,
      lineWidth: 2,
    };

    const pointPaintStyle: CoreoDataPointStyle = {
      color,
      contrastColor: color,
      pointRadius: 4,
      pointOpacity: 1,
      pointBorderColor: '#ffffff',
      pointBorderWidth: 1,
      pointBorderOpacity: 1,
    };

    safelyAddSource(map, this.id, {
      type: 'geojson',
      data,
      generateId: true
    });

    const [polygonP, polygonbP, lineP, pointP] = await Promise.all([
      polygonFillPaint(polygonPaintStyle),
      polygonBorderPaint(polygonPaintStyle),
      linePaint(linePaintStyle),
      pointPaint(pointPaintStyle)
    ]);

    safelyAddLayer(map, {
      id: this.createLayerId('polygon'),
      type: 'fill',
      source: this.id,
      filter: ["==", ["geometry-type"], "Polygon"],
      paint: polygonP
    });

    safelyAddLayer(map, {
      id: this.createLayerId('polygon-outline'),
      type: 'line',
      source: this.id,
      filter: ["==", ["geometry-type"], "Polygon"],
      paint: polygonbP
    });

    safelyAddLayer(map, {
      id: this.createLayerId('linestring'),
      type: 'line',
      source: this.id,
      filter: ["==", ["geometry-type"], "LineString"],
      paint: lineP
    });

    safelyAddLayer(map, {
      id: this.createLayerId('point'),
      type: 'circle',
      source: this.id,
      filter: ["==", ["geometry-type"], "Point"],
      paint: pointP
    });
  }

  layerIds(): string[] {
    return ['polygon', 'polygon-outline', 'linestring', 'point'].map(a => this.createLayerId(a));
  }
}
