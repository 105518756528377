import { MultiPolygon, Polygon } from '@turf/helpers';
import { BBox, Feature, Position } from 'geojson';
import mapboxgl, { IControl, PointLike } from 'mapbox-gl';

import themeService from './theme.service';
import { getGlobalMaskLayer } from './utils.service';
import { safelyAddLayer, safelyAddSource, safelyRemoveLayer, safelyRemoveSource } from './maps/maps.utils';

export const getClusterColors = (): mapboxgl.Expression => {
  const primary = themeService.getPropertyValue('--ion-color-primary') ?? '#51bbd6';
  const shade = themeService.getPropertyValue('--ion-color-primary-shade') ?? '#f1f075';
  const tint = themeService.getPropertyValue('--ion-color-primary-tint') ?? '#f28cb1';

  // TODO these step points (100 and 750) should be dependent on the current this.count
  return [
    'step',
    ['get', 'pc'],
    tint,
    100,
    shade,
    750,
    primary
  ];
}

export const getPrimaryColor = (): string => themeService.getPropertyValue('--ion-color-primary') ?? '#51bbd6';

export const MAP_CLUSTERS_LAYER_ID = 'MAP_CLUSTERS_LAYER_ID';
export const MAP_CLUSTERS_COUNT_LAYER_ID = 'MAP_CLUSTERS_COUNT_LAYER_ID';
export const MAP_CLUSTERS_POINT_LAYER_ID = 'MAP_CLUSTERS_POINT_LAYER_ID';
export const MAP_UNCLUSTERED_POINT_LAYER_ID = 'MAP_UNCLUSTERED_POINT_LAYER_ID';
export const MAP_UNCLUSTERED_POLYGON_LAYER_ID = 'MAP_UNCLUSTERED_POLYGON_LAYER_ID';
export const MAP_UNCLUSTERED_LINE_LAYER_ID = 'MAP_UNCLUSTERED_LINE_LAYER_ID';

const DEFAULT_POLYGON_OPACITY = 0.4;
const DEFAULT_LINE_OPACITY = 0.9;
const DEFAULT_POINT_OPACITY = 1;

export const MAP_DEFAULT_CENTER = [-2.5, 52.31];
export const MAP_DEFAULT_BOUNDS: BBox = [
  2.0153808593734936, 56.6877748258257,
  -7.0153808593762506, 47.45206245445874
];

export const buildOpacity = (opacity: number, ...expression: any[]): mapboxgl.Expression => [
  'case',
  ...expression,
  ['boolean', ['feature-state', 'hidden'], false],
  0,
  opacity
];

export const defaultPolygonOpacity = buildOpacity(DEFAULT_POLYGON_OPACITY);
export const defaultLineOpacity = buildOpacity(DEFAULT_LINE_OPACITY);
export const defaultPointOpacity = buildOpacity(DEFAULT_POINT_OPACITY);

export const MAP_ALL_RECORDS_LAYERS = [
  MAP_CLUSTERS_LAYER_ID,
  MAP_CLUSTERS_COUNT_LAYER_ID,
  MAP_CLUSTERS_POINT_LAYER_ID,
  MAP_UNCLUSTERED_POINT_LAYER_ID,
  MAP_UNCLUSTERED_POLYGON_LAYER_ID,
  MAP_UNCLUSTERED_LINE_LAYER_ID
];

// See https://github.com/mapbox/mapbox-gl-js/issues/9461
// Remove a feature state without throwing errors
// export const safelyRemoveFeatureState = (map: mapboxgl.Map, feature: FeatureIdentifier, state: string) => {
//   const existing = map.getFeatureState(feature);
//   if (existing && existing.hasOwnProperty(state)) {
//     map.removeFeatureState(feature, state);
//   }
// }

// export const safelyAddSource = (map: mapboxgl.Map, id: string, source: AnySourceData, replace = false) => {
//   // If the source already exists and we are not replacing it, return
//   const existing = map.getSource(id);
//   if (typeof existing !== 'undefined' && !replace) {
//     return;
//   }
//   if (existing && replace) {
//     map.removeSource(id);
//   }
//   map.addSource(id, source);
// }

// export const safelyAddLayer = (map: mapboxgl.Map, layer: AnyLayer, replace = false) => {
//   const existing = map.getLayer(layer.id);
//   if (typeof existing !== 'undefined' && !replace) {
//     return
//   }
//   if (existing && replace) {
//     map.removeLayer(layer.id);
//   }
//   map.addLayer(layer);
// }

// export const safelyRemoveLayer = (map: mapboxgl.Map, id: string) => {
//   if (map && typeof map.getLayer(id) !== 'undefined') {
//     map.removeLayer(id);
//   }
// };

// export const safelyRemoveSource = (map: mapboxgl.Map, id: string) => {
//   if (map && typeof map.getSource(id) !== 'undefined') {
//     map.removeSource(id);
//   }
// };

// export const safelyMoveLayer = (map: mapboxgl.Map, id: string, beforeId?: string) => {
//   if (map && typeof map.getLayer(id) !== 'undefined') {
//     map.moveLayer(id, beforeId);
//   }
// }

// export const safelyRemoveControl = (map: mapboxgl.Map, control: IControl) => {
//   if (map?.hasControl(control)) {
//     map.removeControl(control);
//   }
// }

// export const safelySetZoomRange = (map: mapboxgl.Map, layerId: string, minZoom: number, maxZoom: number) => {
//   if (map && typeof map.getLayer(layerId) !== 'undefined') {
//     map.setLayerZoomRange(layerId, minZoom, maxZoom);
//   }
// }

const isMapStyleDark = (style: mapboxgl.Style): boolean => {
  if (style.name?.startsWith('Bing')) {
    return true;
  }

  const darkMapboxStyles = ['Dark', 'Satellite', 'Satellite Streets'];
  if (style.name?.startsWith('Mapbox')) {
    const mapboxStyleName = style.name.substring(7);
    return darkMapboxStyles.includes(mapboxStyleName);
  }
  return false;
}

export class MapboxUserLocationControl implements IControl {

  marker: mapboxgl.Marker;
  userLocation: HTMLDivElement;
  private container: HTMLDivElement;
  private map: mapboxgl.Map;

  constructor(private position?: Position) { }

  onAdd(map: mapboxgl.Map) {
    this.map = map;
    const userLocation = document.createElement('div');
    userLocation.classList.add('mapboxgl-user-location');
    this.userLocation = userLocation;

    const userLocationDot = document.createElement('div');
    userLocationDot.classList.add('mapboxgl-user-location-dot')

    const userLocationHeading = document.createElement('div');
    userLocationHeading.classList.add('mapboxgl-user-location-heading');
    userLocation.appendChild(userLocationDot);
    userLocation.appendChild(userLocationHeading);

    this.marker = new mapboxgl.Marker({
      element: userLocation,
      rotationAlignment: 'map',
      pitchAlignment: 'map'
    });

    if (this.position) {
      this.setPosition(this.position);
    }

    this.container = document.createElement('div');
    return this.container;
  }

  setPosition(position: Position) {
    this.marker.setLngLat([position[0], position[1]]).addTo(this.map);
  }

  setHeading(heading: number) {
    this.marker.setRotation(heading);
    this.userLocation.classList.add('mapboxgl-user-location-show-heading');
  }

  onRemove() {
    this.marker?.remove();
    this.container?.remove();
    this.map = null;
    return this;
  }
}

export const pointToBox = (point: mapboxgl.Point, boxSize: number): [PointLike, PointLike] => {
  return [
    [point.x - boxSize, point.y - boxSize],
    [point.x + boxSize, point.y + boxSize]
  ];
};

export const emptyStyle: mapboxgl.Style = {
  version: 8,
  name: 'empty',
  metadata: {
    'mapbox:autocomposite': true,
    'mapbox:type': 'template'
  },
  glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
  sources: {},
  layers: [
    {
      id: 'background',
      type: 'background',
      paint: {
        'background-color': 'rgba(0,0,0,0)'
      }
    }
  ]
};

export class MapboxBoundsLayerControl implements IControl {
  private color: string;
  private lineColor: string;
  private data: Feature<Polygon | MultiPolygon>;
  private container: HTMLDivElement;
  private map: mapboxgl.Map;

  constructor(private bounds: MultiPolygon | Polygon) {
    this.data = getGlobalMaskLayer(bounds);
  }

  onAdd(map: mapboxgl.Map): HTMLElement {
    this.map = map;
    this.color = isMapStyleDark(map.getStyle()) ? '#fff' : '#000';
    this.lineColor = '#ff0000';

    safelyAddSource(map, 'appBoundsFillSource', {
      type: 'geojson',
      data: this.data
    });

    safelyAddSource(map, 'appBoundsLineSource', {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: this.bounds
      }
    });

    safelyAddLayer(map, {
      id: 'appBoundsFill',
      type: 'fill',
      source: 'appBoundsFillSource',
      paint: {
        'fill-opacity': 0.3,
        'fill-color': this.color
      }
    });

    safelyAddLayer(map, {
      id: 'appBoundsLine',
      type: 'line',
      source: 'appBoundsLineSource',
      paint: {
        'line-color': this.lineColor
      }
    });
    this.container = document.createElement('div');
    return this.container;
  }

  onRemove() {
    safelyRemoveLayer(this.map, 'appBoundsFill');
    safelyRemoveLayer(this.map, 'appBoundsLine');
    safelyRemoveSource(this.map, 'appBoundsFillSource');
    safelyRemoveSource(this.map, 'appBoundsLineSource');

    this.container.remove();
    this.map = null;
    return this;
  }
}
