import { Feature, Geometry } from "geojson";
import { MapLayer } from "./maps-base-layer";
import { CoreoMapLayer } from "../../types";
import { safelyAddLayer, safelyAddSource } from "./maps.utils";
import AppDatabase from "../db/app-db.service";

const getRecordsFeatures = (surveyId: number, projectId: number) => AppDatabase.instance.records.findProjectMapFeatures(q => q.where('surveyId = ?', surveyId).where('projectId = ?', projectId));
const getCollectionFeatures = (collectionId: number, projectId: number) => AppDatabase.instance.items.findProjectMapFeatures(q => q.where('collectionId = ?', collectionId).where('projectId = ?', projectId));


export class MapCustomLayer extends MapLayer {
  private features: Feature<Geometry>[];

  constructor(private config: CoreoMapLayer) {
    super();
  }

  async init() {
    if (this.config.sourceType === 'records') {
      this.features = await getRecordsFeatures(this.config.sourceId, this.config.projectId);
    } else if (this.config.sourceType === 'collections') {
      this.features = await getCollectionFeatures(this.config.sourceId, this.config.projectId);
    }
  }

  async addTo(map: mapboxgl.Map): Promise<void> {
    this.map = map;

    safelyAddSource(map, this.id, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: this.features
      },
      promoteId: 'id'
    });

    const layer: any = {
      id: this.id,
      type: this.config.type ?? 'heatmap',
      source: this.id,
      layout: this.config.layout ?? {},
      paint: this.config.paint ?? {},
    };

    if (this.config.filter) {
      layer.filter = this.config.filter;
    }

    safelyAddLayer(map, layer);
  }

  layerIds(): string[] {
    return [this.id];
  }
}
