import { capSQLiteSet } from "@capacitor-community/sqlite";
import { CoreoForm } from "../../../types";
import { SyncForm } from "../app-sync.service";
import { AppDatabaseRepo } from "./base.db";

export interface AppDatabaseForm {
  id: number;
  projectId: number;
  name: string;
  title: string;
  slug: string;
  anonymous: boolean;
  thankyou: string;
  isPrimary: boolean;
  titleAttributeId: number;
  secondaryTitleAttributeId: number;
  color: string;
  visible: boolean;
  allowMemberUpdate: boolean;
  private: boolean;
  sort: number;
  style: string;
  mapSort: number;
  mapVisible: boolean;
  labelAttributeId: number;
  labelCollectionAttributeId: number;
  styleAttributeId: number;
}

export class AppDatabaseFormRepo extends AppDatabaseRepo<AppDatabaseForm, SyncForm, CoreoForm> {

  columns = [
    ['id', 'INTEGER NOT NULL'],
    ['projectId', 'INTEGER NOT NULL'],
    ['name', 'TEXT NOT NULL'],
    ['title', 'TEXT'],
    ['slug', 'TEXT'],
    ['anonymous', 'INTEGER'],
    ['thankyou', 'TEXT'],
    ['isPrimary', 'INTEGER'],
    ['titleAttributeId', 'INTEGER'],
    ['secondaryTitleAttributeId', 'INTEGER'],
    ['color', 'TEXT'],
    ['visible', 'INTEGER'],
    ['allowMemberUpdate', 'INTEGER'],
    ['private', 'INTEGER'],
    ['sort', 'INTEGER'],
    ['style', 'TEXT'],
    ['mapSort', 'INTEGER'],
    ['mapVisible', 'INTEGER'],
    ['labelAttributeId', 'INTEGER'],
    ['labelCollectionAttributeId', 'INTEGER'],
    ['styleAttributeId', 'INTEGER']

  ];
  indexes = [];
  references = [];

  primaryKey = ["id", "projectId"];
  tableName = 'forms';

  public toInsertSet(form: SyncForm): capSQLiteSet[] {
    const columns = this.columns.map(c => c[0]).join(',');
    const values = this.columns.map(() => '?').join(',');
    return [{
      statement: `INSERT OR REPLACE INTO ${this.tableName} (${columns}) VALUES (${values})`,
      values: [
        form.id,
        form.projectId,
        form.name,
        form.title,
        form.slug,
        form.anonymous,
        form.thankyou,
        form.isPrimary,
        form.titleAttributeId,
        form.secondaryTitleAttributeId,
        form.color,
        form.visible,
        form.allowMemberUpdate,
        form.private,
        form.sort,
        JSON.stringify(form.style),
        form.mapSort,
        form.mapVisible,
        form.labelAttributeId,
        form.labelCollectionAttributeId,
        form.styleAttributeId
      ]
    }];
  }


  async deserialise(form: AppDatabaseForm): Promise<CoreoForm> {
    return {
      ...form,
      style: JSON.parse(form.style)
    };
  }

}
