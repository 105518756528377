import { SQLiteDBConnection } from "@capacitor-community/sqlite";
import { store } from "@stencil/redux";
import { Capacitor } from "@capacitor/core";
import { Build } from '@stencil/core';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { Directory, Filesystem } from "@capacitor/filesystem";
import bboxPolygon from "@turf/bbox-polygon";
import write_blob from "capacitor-blob-writer";
import { BBox } from "geojson";
import mapboxgl from "mapbox-gl";
import PQueue from "p-queue";
import xyz from 'xyz-affair';
import { CONFIGS } from "../config";
import { OfflineMapStatus, OfflineMap } from "../types/offlineMaps.types";
import SQLDatabase from "./db/sql.service";
import { getGlobalMaskLayer } from "./utils.service";
import { offlineMapsRetry } from "../store/offlineMaps/offlineMaps.actions";
import { getOfflineMapsMaps } from "../store/selectors";
import { initialStore } from "../store";
import { Unthunk } from "../types";

class TileNotFoundError extends Error {

}

class MapDownloadError extends Error {

}

type Point = [number, number];
type Bounds = [Point, Point];
const convertBoundsToCorners = (bounds: Bounds): Point[] => {
  const [sw, ne] = bounds;

  const [minLng, minLat] = sw;
  const [maxLng, maxLat] = ne;

  // Southwest corner (already given)
  const southwest: Point = [minLng, minLat];

  // Northwest corner
  const northwest: Point = [minLng, maxLat];

  // Northeast corner (already given)
  const northeast: Point = [maxLng, maxLat];

  // Southeast corner
  const southeast: Point = [maxLng, minLat];

  // return [southwest, northwest, northeast, southeast];
  return [northwest, northeast, southeast, southwest];
}

export interface OfflineMapStyle {
  name: string;
  id: string;
  url: string;
}

export class OfflineMapsService {

  public static instance: OfflineMapsService = new OfflineMapsService();

  private db: SQLiteDBConnection;
  private isNative: boolean = Capacitor.isNativePlatform();

  offlineMapsRetry: Unthunk<typeof offlineMapsRetry>;

  private constructor() {
    store.mapDispatchToProps(this, {
      offlineMapsRetry
    });
  };

  public async init() {
    this.db = await SQLDatabase.instance.createConnection('offline-tiles');
    await this.db.open();
    // (window as any).tileDb = this.db;

    await this.db.execute(`CREATE TABLE IF NOT EXISTS tiles (
      z INTEGER NOT NULL,
      x INTEGER NOT NULL,
      y INTEGER NOT NULL,
      size INTEGER NOT NULL,
      mapUuid TEXT
    )`);

    await this.db.execute(`CREATE INDEX IF NOT EXISTS tiles_uuid ON tiles (
      mapUuid
    )`);

    const maps = getOfflineMapsMaps(initialStore.getState()).filter(s => s.status === OfflineMapStatus.DOWNLOADING);
    this.downloadMaps(maps);
  }

  private async downloadMaps(offlineMapsRetry: OfflineMap[]) {
    for (const map of offlineMapsRetry) {
      await this.offlineMapsRetry(map.uuid);
    }
  }

  async downloadMap(map: OfflineMap): Promise<number> {
    if (map.type === 'vector') {
      return this.downloadVectorMap(map);
    }
    return this.downloadRasterMap(map);
  }

  async downloadRasterMap(map: OfflineMap) {
    const result = await this.getRasterImage(map);
    return result.size;
  }

  async downloadVectorMap(map: OfflineMap) {
    const { bounds } = map;
    const tiles = xyz(bounds, 0, 16);

    if (!Build.isTesting) {
      FirebaseAnalytics.logEvent({
        name: 'offlineMapDownload',
        params: {
          tiles: tiles.length
        }
      });
    }

    if (tiles.length === 0) {
      return 0;
    };

    // Create the parent directory
    await this.mkdir(map);

    const queue = new PQueue({
      concurrency: this.isNative ? 4 : 1,
      interval: 1000,
      intervalCap: 1000
    });

    const tasks = tiles.map(tile => queue.add(async () => {
      const { z, x, y } = tile;

      const blob = await this.getTile(map, z, x, y);
      await OfflineMapsService.instance.writeOfflineTileRecord(z, x, y, blob.size, map.uuid);
      return blob.size;
    }));

    try {
      const result = await Promise.all(tasks);
      const size = result.reduce((acc, s) => acc + s, 0);
      return size;
    } catch (e) {
      console.warn(e);
      throw new MapDownloadError();
    }
  }

  private async getTile(map: OfflineMap, z: number, x: number, y: number) {
    const url = `https://api.mapbox.com/styles/v1/mapbox/${map.styleId}/tiles/${z}/${x}/${y}?access_token=${CONFIGS.prod.MAPBOX_TOKEN}`;
    const resp = await fetch(url);
    if (!resp.ok) {
      throw new TileNotFoundError();
    }

    const blob = await resp.blob();
    const dir = `/${z}/${x}`;
    await this.mkdir(map, dir);

    await this.writeFile(map, `${dir}/${y}`, blob);

    return blob;
  }

  private async getRasterImage(map: OfflineMap): Promise<Blob> {

    // Create some bounds
    const sw = new mapboxgl.LngLat(map.bounds[0][0], map.bounds[0][1]);
    const ne = new mapboxgl.LngLat(map.bounds[1][0], map.bounds[1][1]);

    const minLon = sw.lng;
    const minLat = sw.lat;
    const maxLon = ne.lng;
    const maxLat = ne.lat;

    const lonLatToMercator = (lon: number, lat: number): { x: number; y: number } => {
      const R_MAJOR = 6378137.0;
      const x = (R_MAJOR * lon * Math.PI) / 180;
      let y = R_MAJOR * Math.log(Math.tan((Math.PI / 4) + (lat * Math.PI) / 360));
      return { x, y };
    }

    const swMercator = lonLatToMercator(minLon, minLat);
    const neMercator = lonLatToMercator(maxLon, maxLat);

    const widthMeters = neMercator.x - swMercator.x;
    const heightMeters = neMercator.y - swMercator.y;

    const aspectRatio = widthMeters / heightMeters;
    const maxDimension = 1280;
    let adjustedWidth: number;
    let adjustedHeight: number;

    if (aspectRatio >= 1) {
      adjustedWidth = maxDimension;
      adjustedHeight = Math.round(adjustedWidth / aspectRatio);
    } else {
      adjustedHeight = maxDimension;
      adjustedWidth = Math.round(adjustedHeight * aspectRatio);
    }
    adjustedWidth = Math.min(Math.max(1, adjustedWidth), maxDimension);
    adjustedHeight = Math.min(Math.max(1, adjustedHeight), maxDimension);
    const bbox = `${minLon},${minLat},${maxLon},${maxLat}`;

    const url = `https://api.mapbox.com/styles/v1/mapbox/${map.styleId}/static/[${bbox}]/${adjustedWidth}x${adjustedHeight}@2x?access_token=${CONFIGS.prod.MAPBOX_TOKEN}`;
    console.log('URL IS', url);
    const resp = await fetch(url);
    if (!resp.ok) {
      throw new Error('Error fetching raster image');
    }
    const blob = await resp.blob();
    await this.mkdir(map);
    await this.writeFile(map, '/raster.png', blob);
    return blob;
  }


  public async getOfflineMap(map: OfflineMap): Promise<mapboxgl.Style> {

    const uriResult = await Filesystem.getUri({
      directory: Directory.Data,
      path: this.getStoragePath(map)
    });

    const tileUri = map.type === 'vector' ? `${uriResult.uri}/{z}/{x}/{y}` : `${uriResult.uri}/raster.png`;

    const boundsPolygon = bboxPolygon(map.bounds.flat() as BBox);
    const data = getGlobalMaskLayer(boundsPolygon.geometry);

    const offlineStyle: mapboxgl.Style = {
      version: 8,
      name: 'Offline Map',
      glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
      sources: {
        'offline-bounds': {
          type: 'geojson',
          data
        }
      },
      layers: [{
        'id': 'offline-mask',
        'type': 'fill',
        'source': 'offline-bounds',
        'paint': {
          'fill-color': '#fff'
        }
      }, {
        'id': 'offline-bounds',
        'type': 'line',
        'source': 'offline-bounds',
        'layout': {
          'line-join': 'bevel'
        },
        'paint': {
          'line-color': '#8833FF',
          'line-width': 2,
          'line-dasharray': [2, 2],
        }
      }]
    };

    if (map.type === 'vector') {
      offlineStyle.sources['offline-raster-tiles'] = {
        type: 'raster',
        tiles: [tileUri],
        tileSize: 512,
        maxzoom: 16,
        bounds: map.bounds.flat() as [number, number, number, number]
      };
      offlineStyle.layers.unshift({
        'id': 'offline-tiles',
        'type': 'raster',
        'source': 'offline-raster-tiles',
        'minzoom': 0,
        'maxzoom': 22,
      });
    } else {
      offlineStyle.sources['offline-raster-tiles'] = {
        type: 'image',
        url: tileUri,
        coordinates: convertBoundsToCorners(map.bounds as Bounds) as [[number, number], [number, number], [number, number], [number, number]]
      };
      offlineStyle.layers.unshift({
        'id': 'offline-raster',
        'type': 'raster',
        'source': 'offline-raster-tiles',
        'paint': {
          'raster-fade-duration': 0
        }
      });
    }

    return offlineStyle;
  }

  public async writeOfflineTileRecord(z: number, x: number, y: number, size: number, mapUuid: string = null): Promise<void> {
    await this.db.executeSet([{
      statement: 'INSERT OR REPLACE INTO tiles (z,x,y,size,mapUuid) VALUES (?,?,?,?,?)',
      values: [
        z,
        x,
        y,
        size,
        mapUuid
      ]
    }]);
    if (!this.isNative) {
      await this.db.close();
      await this.db.open();
    }
  }

  public async deleteOfflineMapTiles(uuid: string): Promise<void> {
    const statement = uuid === null ? 'DELETE FROM tiles WHERE mapUuid IS NULL' : 'DELETE FROM tiles WHERE mapUuid = ?';
    const values = uuid === null ? [] : [uuid];

    await this.db.executeSet([{
      statement,
      values
    }]);

    if (!this.isNative) {
      await this.db.close();
      await this.db.open();
    }
  }

  public async getAmbientTileSize(): Promise<number> {
    const result = await this.db.query('SELECT SUM(size) as total FROM tiles WHERE mapUuid IS NULL', []);
    return result?.values?.[0]?.total ?? 0 as number;
  }

  protected getStoragePath(map: OfflineMap) {
    return `/${map.uuid}`;
  }

  protected mkdir(map: OfflineMap, path: string = '') {
    const mkdirPath = `${this.getStoragePath(map)}${path}`;
    return Filesystem.mkdir({
      directory: Directory.Data,
      path: mkdirPath,
      recursive: true
    }).catch(() => { });
  }

  protected async writeFile(map: OfflineMap, path: string, blob: Blob) {
    const writePath = `${this.getStoragePath(map)}${path}`;

    await write_blob({
      directory: Directory.Data,
      path: writePath,
      fast_mode: true,
      blob
    });
  }

  public async deleteMap(map: OfflineMap) {
    await this.destroy(map);
    await this.deleteOfflineMapTiles(map.uuid);
  }

  protected destroy(map: OfflineMap) {
    return Filesystem.rmdir({
      directory: Directory.Data,
      path: this.getStoragePath(map),
      recursive: true
    });
  }

  offlineMapTransformRequestFunction: mapboxgl.TransformRequestFunction = (url, type) => {
    // URL Transofmration of offline tiles
    if (type === 'Tile' && url.startsWith('file://')) {
      const result = Capacitor.convertFileSrc(url);
      return {
        url: result
      };
    }

    if (type === 'Image' && url.startsWith('file://')) {
      const result = Capacitor.convertFileSrc(url);
      return {
        url: result
      };
    }

    return {
      url
    };
  }
}
