import { CoreoGeometryType, GeometrySelectorUserSettings } from '../../types';
import { TypeKeys } from '../actions';

import { FormsSupportActions } from './formsSupport.actions';

export interface FormsSupportFormState {
  geometryType: CoreoGeometryType;
}

export interface FormsSupportAttributeState {
  recent: string[];
  segment: string;
}

export interface FormsSupportState {
  cache: { [id: string]: FormsSupportFormState };
  geometrySetings: GeometrySelectorUserSettings;
  attributes: { [id: string]: FormsSupportAttributeState };
  restoreMediaAttributeId: number;
  restoreMediaAttachmentId: number;
  restoreMediaUrl: string;
};

export const defaultGeometrySettings: GeometrySelectorUserSettings = {
  snapToBaseLayer: false,
  snapTolerance: 20,
  simplificationAmount: 5,
  showMetrics: true
};

const initialState: FormsSupportState = {
  cache: {},
  attributes: {},
  restoreMediaAttributeId: null,
  restoreMediaUrl: null,
  restoreMediaAttachmentId: null,
  geometrySetings: {
    ...defaultGeometrySettings
  }
};

const formsSupportReducer = (state = initialState, action: FormsSupportActions): FormsSupportState => {
  const updateFormCache = (formId: number, update: Partial<FormsSupportFormState>): FormsSupportState => {
    return {
      ...state,
      cache: {
        ...state.cache,
        [formId]: {
          ...state.cache[formId],
          ...update
        }
      }
    };
  };

  switch (action.type) {
    case TypeKeys.FORMS_SUPPORT_UPDATE_GEOMETRY_TYPE: {
      const { formId, geometryType } = action;
      return updateFormCache(formId, { geometryType });
    }

    case TypeKeys.FORMS_SUPPORT_UPDATE_GEOMETRY_SETTINGS: {
      return {
        ...state,
        geometrySetings: {
          ...action.settings
        }
      }
    }

    case TypeKeys.FORMS_SUPPORT_RESET_GEOMETRY_SETTINGS: {
      return {
        ...state,
        geometrySetings: {
          ...defaultGeometrySettings
        }
      }
    }

    case TypeKeys.FORMS_SUPPORT_UPDATE_ATTRIBUTE: {
      const { attributeId, state: attributeState } = action;
      return {
        ...state,
        attributes: {
          ...state.attributes,
          [attributeId]: attributeState
        }
      };
    }

    case TypeKeys.FORMS_SUPPORT_SET_RESTORE_MEDIA_ATTRIBUTE: {
      return {
        ...state,
        restoreMediaAttributeId: action.attributeId,
        restoreMediaAttachmentId: action.attachmentId
      };
    }

    case TypeKeys.FORMS_SUPPORT_SET_RESTORE_MEDIA_URL: {
      return {
        ...state,
        restoreMediaUrl: action.url
      };
    }

    case TypeKeys.FORMS_SUPPORT_CLEAR_RESTORE_MEDIA: {
      return {
        ...state,
        restoreMediaAttributeId: null,
        restoreMediaUrl: null
      };
    }

    default: {
      return state;
    }
  }
}

export default formsSupportReducer;
